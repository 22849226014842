<script setup lang="ts">
import type { PropType } from 'vue';
import type { Singleton } from '~/types/singleton';

const props = defineProps({
  collections: Object as PropType<Array<Singleton>>,
  pending: Boolean as PropType<boolean>,
});
const { collections, pending } = toRefs(props);
</script>

<template>
  <div
    class="grid w-full grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 lg:grid-cols-3 lg:gap-6 2xl:grid-cols-4 2xl:gap-8"
  >
    <CollectionCard v-for="collection in collections" :key="collection.id" :collection="collection" />
    <template v-if="pending">
      <CollectionCard v-for="index in 12" :key="index" :collection="undefined" />
    </template>
  </div>
</template>
